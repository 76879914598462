<template>
  <v-container class="meals" fluid>
    <v-dialog
      v-model="showMissing"
      ref="dialogMissing"
      width="500px"
    >
      <v-card>
        <v-card-title>Warning</v-card-title>
        <v-card-text>
          The following meal ids were not found in the database. Any ingredients required for these meals is not show.
          <v-list>
            <v-list-item v-for="mealId of missing_meals" v-bind:key="mealId">
              {{ missing_count[mealId] }} orders for {{ mealId }}
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-footer fixed class="d-print-none pl-10 pr-8" v-if="datesFormatted">
      <v-chip class="mr-4" color="red" outlined v-if="loading">Loading...</v-chip>
      {{ datesFormatted }}
      <v-spacer/>
      {{ ingredients.length }} ingredients |
      {{ totalCount - totalCountExtras }} meals |
      {{ totalCountExtras }} extras |
      {{ orders.length }} orders |
      ${{ Math.ceil(totalCost) }} cost
      <span v-if="totalCount - totalCountExtras > 0">
        | {{ formatCurrency((totalCost * 100) / (totalCount - totalCountExtras)) }} avg
      </span>
      <v-spacer/>
      <v-chip
        v-if="missing_meals.size"
        color="warning" class="mr-1"
        @click="showMissing=true"
      >{{ missing_meals.size }} meals not found
      </v-chip>
      <v-chip color="secondary"
              close
              @click:close="selected=[]"
              v-if="totalRawSelected">Raw Selected {{ totalRawSelected }}
      </v-chip>
      <v-chip color="primary" v-if="!totalRawSelected">Raw Total {{ totalRawAll }}</v-chip>
    </v-footer>
    <v-toolbar flat class="pb-10">
      <v-text-field
        class="d-print-none"
        v-model="searchInput"
        append-icon="mdi-magnify"
        label="Search By Ingredient"
        single-line
        hide-details
        :disabled="loading"
        clearable
        @blur="search=searchInput"
        @keyup.enter="search=searchInput"
        placeholder="press enter to search"
      />
      <v-spacer/>
      <v-select
        class="d-print-none"
        label="Filter by category"
        v-model="categorySelected"
        :items="categories"
        multiple
        single-line
        hide-details
        :disabled="loading"
      />
      <v-spacer/>
      <v-select
        label="Filter by stream"
        v-model="streamsSelected"
        :items="streams"
        multiple
        single-line
        hide-details
        :disabled="loading"

      />
      <v-spacer/>
      <v-select
        label="Filter by time of day"
        v-model="todSelected"
        :items="'breakfast,lunch,dinner,extras'.split(',')"
        multiple
        single-line
        hide-details
        :disabled="loading"

      />
      <v-spacer/>
      <v-select
        label="Filter by team"
        v-model="teamsSelected"
        :items="loading ? [] : teams"
        multiple
        single-line
        hide-details
        :disabled="loading || disableTeamFilter"
      />
      <v-spacer/>
      <v-checkbox
        class="d-print-none"
        v-model="showMeals"
        dense
        label="Show Meals"
        hide-details
        :disabled="loading"
      />
      <v-checkbox
        class="d-print-none"
        v-model="showPrepOnly"
        dense
        label="Only w/ Prep"
        hide-details
        :disabled="loading"
      />
      <v-spacer/>
      <template v-if="dateRangePicker">
        <v-text-field
          :value="datesFormatted"
          :disabled="loading"
          label="Select dates"
          single-line
          hide-details
          readonly
          @click="showDatePicker=true"
          append-icon="mdi-calendar"
        />
        <v-dialog
          v-model="showDatePicker"
          ref="dialog"
          width="290px"
          persistent
        >
          <v-date-picker
            v-model="datePickerDates"
            :range="dateRangePicker"
            no-title
            @close="closeDatePicker"
          ></v-date-picker>
          <v-btn @click="closeDatePicker">Close</v-btn>
        </v-dialog>
      </template>
      <ProductionSelector
        v-if="!dateRangePicker"
        :dates.sync="dates"
        :production.sync="production"
      />
      <v-dialog
        v-model="showReplaceIngredients"
        ref="dialog"
        max-width="800px"
      >
        <v-card>
          <v-card-title>Replace Ingredient</v-card-title>
          <v-card-text>
            Choose an ingredient you would like to use as a replacement for
            {{ replaceSelected.map(s => (`${s.name.split(',')[0]} [${s.foodid}]`)).join(', ') }}
            <IngredientAutocomplete
              @updateValue="replacement.ingredient=$event"
            />
            Meals containing the following ingredients will be modified
            <ul>
              <li v-for="item of replaceSelected" v-bind:key="item.foodid">
                {{ item.name }}
                <ul>
                  <li v-for="mealId of Object.keys(ingredientToMealMap[item.foodid])"
                      v-bind:key="mealId">
                    {{ getMeal(getMealId(mealId)).name }} ({{ mealId }} ) {{
                      ingredientToMealMap[item.foodid][mealId]
                    }}CT
                  </li>
                </ul>
              </li>
            </ul>

            <br/>
            <p class="caption">Note: a meal/component cannot have duplicate ingredients, so you cannot replace something
              with an ingredient that already exists in the meal.
              You are allowed have the same ingredient as long as they are in different components of the same meal.</p>
          </v-card-text>

          <v-card-actions>

            <v-spacer/>
            <v-btn @click="cancelReplaceIngredients">Cancel</v-btn>
            <v-btn @click="closeReplaceIngredients">Apply</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

    </v-toolbar>

    <v-progress-linear indeterminate v-if="loading"/>
    <div v-if="!loading">
      <v-row dense>
        <v-col class="d-print-none">
          <v-btn outlined @click="includeAllergy=!includeAllergy">{{ includeAllergy ? 'exclude' : 'include' }} allergy
            orders
          </v-btn>
          <v-btn class="ml-2" outlined v-if="showExportButton" @click="csvExport(dataAsTable,filename)">Export to CSV
          </v-btn>
        </v-col>
        <v-col>
          <v-alert dense outlined v-if="includeAllergy" type="warning">Warning: includes allergy amounts</v-alert>
          <v-alert dense outlined v-if="!includeAllergy" type="info">Does not include allergy amounts ('extra protein'
            and 'extra large' are included)
          </v-alert>
        </v-col>
      </v-row>
      <slot name="table"
            v-bind:props="{
                loading, orders,
                getCookedAmount,getRawAmount,
                ingredients, headers, search, visibleHeaders, selected, loading, mealIds, steps, showMeals,
                getMealsUsingIngredient, ingredientToMealCookedMap, ingredientToMealRawMap, formatWeightWithUnits,
                componentIds,
                getComponentMealIds,
                getComponentMealRawAmount,
                getComponentMealCookedAmount,
                getDatesInRange,
                getComponentTotalCookedAmount
            }"
      >
        <v-data-table
          v-model="selected"
          :headers="visibleHeaders"
          :items="ingredients"
          item-key="foodid"
          :search="search"
          :disable-pagination=!paging
          :hide-default-footer=!paging
          :fixed-header=false
          :show-select=true
          :loading="loading"
        >
          <template v-slot:item.name="{ item }">
            <v-text-field
              v-if="item.showEditName"
              v-model="item.editName"
              dense
              single-line
              class="table-input"
              @change="saveName(item)"
              autofocus
              @blur="item.showEditName=false"
            />
            <span v-if="!item.showEditName" :class="enlargePrep?'large-prep':''">
            <strong>{{
                item.nameOnLabel.toLowerCase()
              }}</strong>{{ item.name.replace(item.nameOnLabel, '').split(':::')[0] }}
              <v-icon color="blue" v-if="item.verified">mdi-shield-check</v-icon>
            <span v-if="item.name.split(':::')[1]">
              <br/>
              {{ item.name.split(':::')[1] }}
            </span>
            <span v-if="showMeals">
              <br/>
              <!--              <pre>{{ getMealsUsingIngredient(item) }}</pre>-->
              <div dense v-for="meal of getMealsUsingIngredient(item)" v-bind:key="meal.id"
                   :class="enlargePrep?'large-prep':'caption'"
              >
                <span>
                  <v-chip x-small text-color="white"
                          :color="getDietColor(meal.diet)">
                    {{ getDietName(meal.diet) }}
                  </v-chip>
                </span>
                <span>
                {{ meal.mealCount }} CT {{ meal.name }} (<router-link
                  :to="{ name: 'MealDetail', params: { id: meal.id, date: formatDate(meal.date)}}">{{
                    meal.id
                  }}</router-link>)
                  <!--                  <b>{{ dateFormatted(meal.date, {formatString: 'ddd'}) }} {{ getTimeOfDay(meal.tod) }}</b>-->
                  <!--                  <pre>{{getMealFoods(meal.id,item.foodid)}}</pre>-->
                <span v-for="i of getMealFoods(meal.id,item.foodid)"
                      v-bind:key="[i.mealid,i.foodid,i.component_id].join(',')">

                  - {{ i.component.name }} {{
                    formatWeightWithUnits(ingredientToMealCookedMap[i.foodid][getKey(meal.id, i.component_id)])
                  }}
                  <span
                    v-if="formatWeightWithUnits(ingredientToMealRawMap[i.foodid][getKey(meal.id,i.component_id)])!==formatWeightWithUnits(ingredientToMealCookedMap[i.foodid][getKey(meal.id,i.component_id)])">
                    (raw {{ formatWeightWithUnits(ingredientToMealRawMap[i.foodid][getKey(meal.id, i.component_id)]) }})
                  </span>
                  <strong> {{ i.prep_instruction }}</strong>
                </span>

                  </span>
              </div>
            </span>
          </span>
          </template>
          <template v-slot:item.category="{ item }">
            <!--          v-if="item.showEditCategory"-->
            <v-text-field
              :disabled="readOnly"
              v-model="item.category"
              dense
              hide-details
              single-line
              class="table-input"
              @change="saveCategories(item)"
            />
            <!--          <span v-if="!item.showEditCategory"-->
            <!--                @click="item.showEditCategory=true">-->
            <!--            {{ item.category }}-->
            <!--          </span>-->
          </template>
          <template v-slot:item.note="{ item }">
            <v-text-field
              :disabled="readOnly"
              v-model="item.note"
              dense
              hide-details
              single-line
              class="table-input"
              @change="saveNote(item)"
            />
          </template>
          <template v-slot:item.price="{ item }">
            <v-text-field
              class="table-input ma-0"
              style="width: 60px"
              v-if="!readOnly"
              v-model="item.price"
              type="number"
              suffix="$"
              reverse
              dense
              min="0"
              hide-details
              single-line
              @blur="savePrice(item)"
              @keyup.native.enter="savePrice(item)"
            />
            <span v-if="readOnly && item.price">
              ${{ item.price }}
          </span>
          </template>
          <template v-slot:item.totalPrice="{ item }">
            <!--          <v-text-field-->
            <!--              style="width: 80px"-->
            <!--              v-if="!readOnly"-->
            <!--              v-model="item.totalPrice"-->
            <!--              type="number"-->
            <!--              suffix="$"-->
            <!--              reverse-->
            <!--              dense-->
            <!--              hide-details-->
            <!--              single-line-->
            <!--              class="table-input"-->
            <!--              @blur="saveTotalPrice(item)"-->
            <!--              @keyup.native.enter="saveTotalPrice(item)"-->
            <!--          />-->
            <!--          <span v-if="readOnly && item.totalPrice">-->
            <span v-if="item.totalPrice">
              ${{ item.totalPrice }}
          </span>
          </template>
          <template v-slot:item.percentChange="{ item }">
          <span v-if="item.percentChange">
              {{ item.percentChange }}%
          </span>
          </template>
          <template v-slot:item.percentTrim="{ item }">
            <v-text-field
              class="table-input ma-0"
              style="width: 60px"
              v-if="!readOnly"
              v-model="item.percentTrim"
              type="number"
              prefix="%"
              reverse
              dense
              min="0"
              hide-details
              single-line
              @blur="saveTrim(item)"
              @keyup.native.enter="saveTrim(item)"
            />
            <span v-if="readOnly && item.percentTrim">
              ${{ item.percentTrim }}%
          </span>
          </template>
          <template v-slot:item.rawAmountValue="{ item }">
            {{ formatWeightWithUnits(item.rawAmountValue) }}
            <v-spacer/>
          </template>
          <template v-slot:item.buyAmountValue="{ item }">
            {{ formatWeightWithUnits(item.buyAmountValue) }}
            <v-spacer/>
          </template>

          <template v-slot:item.cookedAmountValue="{ item }">
          <span v-if="item.percentChange">
              {{ formatWeightWithUnits(item.cookedAmountValue) }}
          </span>
          </template>
          <template v-slot:item.actions="{ item }" v-if="!readOnly">
            <v-btn class="d-print-none ma-1" small rounded @click="item.showEditName=true">Edit Name</v-btn>
            <v-btn class="d-print-none ma-1" small rounded @click="showReplaceItem(item)">Replace</v-btn>
          </template>
        </v-data-table>
      </slot>

      <!-- blank gap at the bottom to avoid fixed footer hiding data-table -->
      <div class="ma-8"></div>
    </div>
  </v-container>
</template>

<script>
import IngredientAutocomplete from '@/components/IngredientAutocomplete';
import {mapActions, mapGetters} from 'vuex';
import {
  csvAsTable,
  csvExport,
  dateFormatted,
  ensureNumber,
  filterByAllergyDetected,
  formatCurrency,
  formatWeight,
  getDateString,
  removeCC
} from '@/store/utils';
import moment from 'moment';
import api from '@/api';
import urlState from "@/router/urlState";
import ProductionSelector from "@/components/ProductionSelector.vue";
import diff from "microdiff";

function getMealId(key) {
  return key.split('.')[0];
}

function getComponentId(key) {
  return key.split('.')[1];
}

function getKey(mealId, componentId) {
  return componentId ? `${mealId}.${componentId}` : mealId;
}

export default {
  name: "IngredientsOrder",
  components: {ProductionSelector, IngredientAutocomplete},
  mixins: [urlState],
  data() {
    return {
      mealIds: [],
      units: ['g', 'kg'],
      decimalPlaces: [1, 2],
      respondToRouteChanges: true,
      streamsSelected: [],
      teamsSelected: [],
      teamNotSet: '(blank)',
      todSelected: [],
      selected: [],
      replaceSelected: [],
      missing_meals: new Set(),
      missing_count: {},
      showDatePicker: false,
      showReplaceIngredients: false,
      replacement: {},
      dates: [],
      search: '',
      loading: false,
      post: null,
      error: null,
      multiview: false,
      multiviewMealSelected: {},
      headers: [
        {
          text: 'Categories (eg, Supplier)',
          align: 'start',
          value: 'category',
          // width: '*'
        },
        {
          text: 'Notes (eg, SKU)',
          align: 'start',
          value: 'note',
          // width: '*'
        },
        {
          text: 'Ingredient (Bold text is displayed to customers)',
          align: 'start',
          value: 'name',
          // width: '*'
        },
        {
          text: 'Cooked Amount',
          align: 'end',
          value: 'cookedAmountValue',
        },
        {
          text: '% Change',
          align: 'end',
          value: 'percentChange',
        },
        {
          text: 'Raw Amount',
          align: 'end',
          sortable: true,
          value: 'rawAmountValue',
        },
        {
          text: '% Trim',
          align: 'end',
          value: 'percentTrim',
        },
        {
          text: 'Buy Amount',
          align: 'end',
          sortable: true,
          value: 'buyAmountValue',
        },
        {
          text: 'Price ($/kg)',
          align: 'end',
          value: 'price',
        },
        {
          text: 'Price Date',
          value: 'priceDate'
        },
        {
          text: 'Total ($)',
          align: 'end',
          value: 'totalPrice',
        },
        {
          text: '',
          sortable: false,
          value: 'actions',
        },
      ],
      showMissing: false,
      setRouteTimeout: null,
      filteredIngredients: [],
      categorySelected: [],
      ingredientToMealMap: {},
      ingredientToMealRawMap: {},
      ingredientToMealCookedMap: {},
      componentToMealRawMap: {},
      componentToMealCookedMap: {},
      readOnly: true,
      showMeals: true,
      showPrepOnly: true,
      steps: {},
      datePickerDates: null,
      searchInput: null,
      includeAllergy: false,
      production: null,
      currentData: {dates: [], production: ''},
      ordersUnfiltered: []
    }
  },
  created() {
    this.readOnly = !!this.$route.meta.readOnly;
    this.showPrepOnly = this.prepOnly;
    this.syncToUrl({
      param: 'includeAllergy', urlParam: 'inc_allergy', initFromRoute: true,
      parseCallback: (v) => v === 'true'
    });
    this.syncToUrl({
      param: 'search', urlParam: 'search', initFromRoute: true,
    });
    this.syncToUrl({
      param: 'todSelected', urlParam: 'tod', initFromRoute: true,
    });
    this.syncToUrl({
      param: 'streamsSelected', urlParam: 'streams', initFromRoute: true,
      parseCallback: (v) => Array.isArray(v) ? v : [v]
    });
    this.syncToUrl({
      param: 'teamsSelected', urlParam: 'teams', initFromRoute: true,
      parseCallback: (v) => Array.isArray(v) ? v : [v]
    });
    this.syncToUrl({
      param: 'dates', urlParam: 'dates', initFromRoute: true,
      parseCallback: (v) => Array.isArray(v) ? v : [v]
    });
    this.syncToUrl({
      param: 'production', urlParam: 'production', initFromRoute: true,
    });
  },
  watch: {
    dates: 'fetchData',
    production: 'fetchData',
    showDatePicker(v) {
      if (v) {
        if (this.dateRangePicker) {
          this.datePickerDates = this.dates;
        } else {
          this.datePickerDates = this.dates[0];
        }
      }
    },
    search(v) {
      if (v !== this.searchInput) {
        this.searchInput = v;
      }
    },
    excludeAllergy(v) {
      this.includeAllergy = !v;
    }
  },
  mounted() {
    this.includeAllergy = !this.excludeAllergy;
    return this.fetchData();
  },
  computed: {
    ...mapGetters([
      'meals',
      'diets',
      'getMeal',
      'getMealIngredients',
      'getDietName',
      'getDietColor',
      'getDietStream',
      'getTimeOfDay',
      'getSummaryForDiet',
    ]),
    categories() {
      const categories = new Set();
      // console.log('building categories', this.ingredients)
      if (this.ingredients) {
        this.ingredients.forEach(i => i.categories.forEach(c => categories.add(c)));
      }
      // console.log('categories', categories);
      return [...categories];
    },
    orders() {
      let orders = this.ordersUnfiltered;
      // console.log('orders', orders);
      const streamsSelected = this.streamsSelected;
      if (streamsSelected.length > 0) {
        orders = orders.filter(o => streamsSelected.includes(o.stream));
      }
      if (this.todSelected.length > 0) {
        orders = orders.filter(o => this.todSelected.includes(o.tod));
      }
      return orders;
    },
    components() {
      if (this.loading) {
        // console.log('ingredients - still loading, returning []');
        return [];
      } else
        throw new Error('this method should not be used');
      // return 'poop';
    },
    componentIds() {
      if (this.loading) {
        // console.log('ingredients - still loading, returning []');
        return [];
      }
      const ids = Object.keys(this.componentToMealRawMap);
      // console.log('component ids', ids);
      return ids;
    },

    ingredients() {
      if (this.loading) {
        // console.log('ingredients - still loading, returning []');
        return [];
      }
      // console.log('ingredients - counting orders');
      const ingredientAmounts = {};
      this.missing_meals.clear();
      this.$set(this, 'missing_count', {});
      this.$set(this, 'ingredientToMealMap', {});
      this.$set(this, 'ingredientToMealRawMap', {});
      this.$set(this, 'ingredientToMealCookedMap', {});
      this.$set(this, 'componentToMealRawMap', {});
      this.$set(this, 'componentToMealCookedMap', {});

      this.orders.forEach(order => {
          let mealId = removeCC(order.meal_id);
          const steps = this.steps[mealId] || {components: {}, ingredients: {}};
          const mealIngredients = mealId && this.getMealIngredients(mealId);
          if (!mealIngredients) {
            // console.log('could not find meal' + order.meal_id, order);
            if (!mealId) {
              const {date, stream, tod} = order;
              mealId = `${stream} ${tod} ${date && moment(date).format('dddd')} ${date}`;
            }
            this.missing_meals.add(mealId)
            this.missing_count[mealId] = this.missing_count[mealId] || 0;
            this.missing_count[mealId]++;
          } else {
            const componentFiltered = {};
            mealIngredients.forEach(ingredient => {
                const componentId = ingredient.component_id;
                if (this.includeAllergy) {
                  // don't check
                } else if (componentFiltered[componentId] === true) {
                  // skip - already determined to skip component
                  return;
                } else if (componentFiltered[componentId] === false) {
                  // do not skip
                } else {
                  const componentIngredients = mealIngredients.filter(i => i.component_id === componentId);
                  componentFiltered[componentId] = filterByAllergyDetected([order], componentIngredients.map(i => i.foodid)).length === 0;
                  if (componentFiltered[componentId]) {
                    // skip
                    // console.log(`should skip component ${componentId}`, Object.keys(order.allergyDetected));
                    return;
                  }
                }


                const mealComponentKey = getKey(mealId, componentId);
                const ingredientStep = this.showIngredientSteps ? steps.ingredients[ingredient.id] : false;
                const componentStep = this.showComponentSteps && componentId ? steps.components[componentId] : false;
                if (this.showPrepOnly && !((this.showIngredientSteps && ingredient.prep_instruction) || ingredientStep || componentStep)) {
                  return;
                }

                // const name = `${ingredient.fooddescriptionsimple} ${componentId} ${mealId}`;
                // console.log('checking', name);
                const isStepSelected = (step) => {
                  // console.log('check isStepSelected', step);
                  if (!step || !step.team) {
                    // no team defined
                    // console.log('no team defined', step, this.teamNotSet);
                    if (!this.teamsSelected.includes(this.teamNotSet))
                      return false;
                  } else if (!this.teamsSelected.includes(step.team)) {
                    // wrong team (or no team)
                    return false;
                  }
                  return true;
                }

                const ingredientStepSelected = this.showIngredientSteps && isStepSelected(ingredientStep);
                const componentStepSelected = this.showComponentSteps && isStepSelected(componentStep);

                if (this.teamsSelected.length > 0 && (!ingredientStepSelected && !componentStepSelected)) {
                  // console.log(name + 'skipping because not selected', {ingredientStepSelected, componentStepSelected});
                  return;
                } else {
                  // console.log(name + 'including because selected', {ingredientStepSelected, componentStepSelected});
                }

                if (this.categorySelected.length > 0) {
                  if (!this.categorySelected.some(c => ingredient.categories.includes(c))) {
                    // skip since this isn't selected
                    return;
                  }
                }
                if (!ingredientAmounts[ingredient.foodid]) {
                  let name = ingredient.fooddescriptionsimple + ` [${ingredient.foodid}]`;
                  // yeah, a little hacky, but this means we can text search.  in the template ::: is replaced with <br/>
                  if (ingredient.fooddescriptionsimple !== ingredient.fooddescription) {
                    name += ':::' + ingredient.fooddescription;
                  }

                  if (this.search && !new RegExp(this.search, 'i').test(name)) {
                    return;
                  }
                  // first time we've seen this ingredient, so set up the totals/etc
                  this.ingredientToMealMap[ingredient.foodid] = this.ingredientToMealMap[ingredient.foodid] || {};
                  this.ingredientToMealRawMap[ingredient.foodid] = this.ingredientToMealRawMap[ingredient.foodid] || {};
                  this.ingredientToMealCookedMap[ingredient.foodid] = this.ingredientToMealCookedMap[ingredient.foodid] || {};
                  this.ingredientToMealMap[ingredient.foodid][mealComponentKey] = this.ingredientToMealMap[ingredient.foodid][mealComponentKey] || 0;
                  ingredientAmounts[ingredient.foodid] = {
                    rawAmountValue: 0,
                    buyAmountValue: 0,
                    cookedAmountValue: 0,
                    foodid: ingredient.foodid,
                    name: name,
                    nameOnLabel: ingredient.fooddescriptionsimple.split(',')[0],
                    // original name in db
                    editName: ingredient.fooddescriptionsimple,
                    showEditName: false,
                    category: (ingredient.categories || []).join(', '),
                    categories: ingredient.categories,
                    note: ingredient.note,
                    verified: ingredient.verified || false,
                    price: (ingredient.price !== null) ? (ingredient.price / 100).toFixed(2) : undefined,
                    priceDate: ingredient.priceDate && moment(ingredient.priceDate).format('LL'),
                    // used when saving/to detect change
                    oldPrice: ingredient.price,
                    showEditCategory: false,
                    percentChange: ensureNumber(ingredient.percentChange) || '',
                    percentTrim: ensureNumber(ingredient.percentTrim) || '',
                    combine: false,
                  }
                  // console.log('ingredient price', ingredient.price, ingredient);
                }


                //console.log('adding raw amount', [ ingredientAmounts[ingredient.foodid].name,order.meal_size, ingredient.rawAmounts[order.meal_size] ]);
                const mealSize = order.meal_size;
                const rawAmountForOrder = order.quantity * Number.parseFloat(ingredient.rawAmounts[mealSize]);
                const cookedAmountForOrder = order.quantity * Number.parseFloat(ingredient.cookedAmounts[mealSize]);
                const buyAmountForOrder = order.quantity * Number.parseFloat(ingredient.buyAmounts[mealSize]);
                ingredientAmounts[ingredient.foodid].rawAmountValue += rawAmountForOrder;
                ingredientAmounts[ingredient.foodid].cookedAmountValue += cookedAmountForOrder;
                ingredientAmounts[ingredient.foodid].buyAmountValue += buyAmountForOrder;
                this.ingredientToMealMap[ingredient.foodid][mealComponentKey] = this.ingredientToMealMap[ingredient.foodid][mealComponentKey] || 0;
                this.ingredientToMealMap[ingredient.foodid][mealComponentKey] += order.quantity;
                this.ingredientToMealRawMap[ingredient.foodid][mealComponentKey] = this.ingredientToMealRawMap[ingredient.foodid][mealComponentKey] || 0;
                this.ingredientToMealRawMap[ingredient.foodid][mealComponentKey] += rawAmountForOrder;
                this.ingredientToMealCookedMap[ingredient.foodid][mealComponentKey] = this.ingredientToMealCookedMap[ingredient.foodid][mealComponentKey] || 0;
                this.ingredientToMealCookedMap[ingredient.foodid][mealComponentKey] += cookedAmountForOrder;

                // add up amounts for components
                this.componentToMealRawMap[componentId] = this.componentToMealRawMap[componentId] || {};
                this.componentToMealCookedMap[componentId] = this.componentToMealCookedMap[componentId] || {};
                this.componentToMealRawMap[componentId][mealId] = this.componentToMealRawMap[componentId][mealId] || 0;
                this.componentToMealRawMap[componentId][mealId] += rawAmountForOrder;
                this.componentToMealCookedMap[componentId][mealId] = this.componentToMealCookedMap[componentId][mealId] || 0;
                this.componentToMealCookedMap[componentId][mealId] += cookedAmountForOrder;

                if (ingredient.price !== null) {
                  ingredientAmounts[ingredient.foodid].totalPrice = ((parseInt(ingredient.price) / 100) * (ingredientAmounts[ingredient.foodid].buyAmountValue / 1000)).toFixed(2);
                }
              }
            )
            ;
          }
        }
      )
      ;

      // console.log('this.componentToMealRawMap', Object.keys(this.componentToMealRawMap));
      // Object.values(ingredientAmounts).forEach(ingredientAmount => {
      //   ingredientAmount.rawAmount = formatWeight(ingredientAmount.rawAmountValue, this.units, this.decimalPlaces);
      //
      //   if (ingredientAmount.percentChange) {
      //     ingredientAmount.cookedAmount = formatWeight(ingredientAmount.cookedAmountValue, this.units, this.decimalPlaces);
      //   } else {
      //     ingredientAmount.cookedAmount = '';
      //   }
      // })
      // console.log('ingredientAmounts', ingredientAmounts);
      if (this.missing_meals.length > 0) {
        console.log('missing meals', this.missing_meals);
      }

      this.$emit('update:component-ids', this.componentIds);
      return Object.values(ingredientAmounts).sort((a, b) => {
        return a.name.localeCompare(b.name);
      });
    },
    totalCount() {
      let count = 0;
      this.orders.forEach(o => count += o.quantity);
      return count;
    },
    totalCountExtras() {
      let count = 0;
      this.orders
        .filter(o => o.tod === 'extras')
        .forEach(o => count += o.quantity);
      return count;
    },
    totalCost() {
      return this.ingredients.reduce((a, i) => a + parseFloat(i.totalPrice || 0), 0);
    },
    mealsByDiet() {
      return this.getMealsByDiet(this.date);
    },
    summary() {
      return this.$store.getters.summary(this.date);
    },
    totalRawSelected() {
      const totals = this.getTotals(this.selected);
      //console.log('totals', totals);
      return totals.rawAmount;
    },
    totalRawAll() {
      const totals = this.getTotals(this.ingredients);
      return totals.rawAmount;
    },

    streams() {
      const streams = new Set();
      this.ordersUnfiltered.forEach(o => streams.add(o.stream));
      // console.log('streams', streams);
      return [...streams.values()].sort();
    },
    datesFormatted() {
      const format = 'ddd MMM D';
      if (!this.dateFrom) {
        return '';
      } else if (this.dateFrom === this.dateTo) {
        return `${moment(this.dateFrom).format(format)}`;
      } else {
        return `${moment(this.dateFrom).format(format)} - ${moment(this.dateTo).format(format)}`
      }
    },
    dateFrom() {
      return [...this.dates].sort()[0];
    },
    dateTo() {
      return [...this.dates].sort().reverse()[0];
    },
    visibleHeaders() {
      if (this.hideHeaders) {
        return this.headers.filter(({value}) => !this.hideHeaders.includes(value));
      } else {
        return this.headers;
      }
    },
    teams() {
      const teams = new Set();
      Object.values(this.steps)
        .forEach(steps => {
          Object.values(steps.ingredients).forEach(s => teams.add(s.team));
          Object.values(steps.components).forEach(s => teams.add(s.team));
        });
      console.log('computed teams', teams);
      return [...teams, this.teamNotSet].filter(t => !!t);
    },
    filename() {
      if (this.search) {
        return `shopping-${this.dates.join('-')}-${this.search}.csv`;
      } else {
        return `shopping-${this.dates.join('-')}.csv`;
      }
    },
    dataAsTable() {
      if (this.dataAsTableFunction) {
        return this.dataAsTableFunction(this.ingredients, this.visibleHeaders, this.search);
      } else {
        return csvAsTable(this.ingredients, this.visibleHeaders, this.search);
      }
    }
  },
  methods: {
    csvExport,
    formatCurrency,
    ...mapActions([
      'fetchDiets',
      'fetchMeals',
      'fetchMeal',
      'fetchMealIngredients',
      'updateIngredientPartial',
      'replaceIngredient'
    ]),
    getKey,
    getComponentId,
    getMealId,
    getComponentMealIds(componentId) {
      return Object.keys(this.componentToMealRawMap[componentId] || {});
    },
    getComponentMealRawAmount(componentId, mealId) {
      return this.componentToMealRawMap[componentId] && this.componentToMealRawMap[componentId][mealId];
    },
    getComponentMealCookedAmount(componentId, mealId) {
      return this.componentToMealCookedMap[componentId] && this.componentToMealCookedMap[componentId][mealId];
    },
    getComponentTotalCookedAmount(componentId) {
      return Object
        .values(this.componentToMealCookedMap[componentId] || {})
        .reduce((i, sum) => i + sum, 0);
    },

    formatWeightWithUnits(amount) {
      return formatWeight(amount, this.units, this.decimalPlaces);
    },
    rowClicked(value) {
      const selected = this.selected;
      if (selected[value.foodid]) {
        this.$delete(selected, value.foodid);
      } else {
        this.$set(selected, value.foodid, value);
      }
    },
    getDatesInRange() {
      const dates = [];
      if (!this.dateFrom || !this.dateTo) {
        return dates;
      }
      const from = moment(this.dateFrom);
      const to = moment(this.dateTo);

      const diff = moment.duration(to.diff(from));
      if (diff.asDays() >= 0 && diff.asDays() <= 31) {
        // console.log('adding days ', {from, to})
        while (from.isSameOrBefore(to)) {
          const dateString = getDateString(from);
          // console.log('added date', dateString);
          dates.push(dateString);
          from.add(1, 'day');
        }
      } else {
        window.alert(diff.asDays() + ' days is above the limit of 7');
      }
      // console.log('getDatesInRange', dates);
      return dates;
    },
    async fetchData() {
      this.error = this.post = null
      const dates = this.dates;
      const production = this.dateRangePicker ? 'all' : this.production;
      if (!dates || dates.length === 0) {
        console.log('no dates, no fetch')
        return;
      }
      if (!production) {
        console.log('no production, no fetch');
        return;
      }

      if (diff(this.currentData, {dates, production}).length === 0) {
        console.log('already loaded', this.currentData);
        return
      } else {
        console.log('fetching prep', {dates, production});
        this.currentData = {dates, production};
      }

      this.loading = true;

      // console.log('fetching meal orders for ', this.dates);
      // console.log('fetching meal orders for ', this.dates
      //     .filter(date => date.length !== 0));

      try {
        // const ordersByDate = await Promise.all(this.getDatesInRange().map(date => this.fetchOrdersByDate({date})));
        const ordersByDate = await api
          .get('v2/order/search', {
            params: {
              from: this.dateFrom,
              to: this.dateTo,
              productions: [production],
              properties: ['quantity', 'allergyDetected', 'allergies', 'meal_id', 'stream', 'tod', 'meal_size'],
            }
          })
          .then(({data}) => data);
        const meals = {};
        console.log('ordersByDate', ordersByDate);
        this.ordersUnfiltered = ordersByDate;
        ordersByDate
          .forEach(order => {
            if (!order.meal_id) {
              console.error('no meal id ', order);
            }
            meals[order.meal_id] = meals[order.meal_id] || {};
            const mealCount = meals[order.meal_id];
            mealCount[order.meal_size] = mealCount[order.meal_size] || 0;
            mealCount[order.meal_size] += order.quantity;
          });
        // console.log('meals', meals);

        const mealIds = this.mealIds = Object.keys(meals).map(removeCC);
        await Promise
          .all([
            ...mealIds.map(id => this.fetchMealIngredients({
              id,
              force: true
            })
              .then((ingredients) => {
                if (this.doNotLoadTeamAssignments) {
                  // do nothing
                } else
                  return this.fetchSteps(id)
                    .then(steps => {
                      steps.ingredientSteps.forEach(s => {
                        const ingredient = ingredients.find(i => s.meal_food_id === i.id);
                        if (!ingredient) {
                          console.error('failed to find meal food for step', {
                            s,
                            ingredients
                          });
                        } else {
                          // console.log('injected ingredient steps', ingredient.fooddescriptionsimple, s.team, s.id);
                          ingredient.assigned = s.team;
                          ingredient.instruction = s.instruction || ingredient.instruction;
                          ingredient.prep_instruction = ingredient.instruction || ingredient.prep_instruction;
                        }
                      });
                    })
              })
              .catch(e => {
                console.warn('failed to get ingredients for ' + id, e);
              })),
            ...mealIds.map(id =>
              this.fetchMeal(id)
                // .then(m => console.log('fetched meal', [id, m.name]))
                .catch(e => {
                  console.warn('failed to get meal ' + id, e);
                })),
            this.fetchDiets()
          ]);

        this.$emit('update:steps', this.steps);
      } catch (e) {
        alert('failed during loading');
        console.error('something went wrong', e);
      }

      this.loading = false;
    },
    async fetchSteps(mealId) {
      return api.get(`v2/meal/${mealId}/steps`)
        .then(({data}) => {
          // console.log('got steps', data);
          const steps = {
            components: {},
            ingredients: {}
          };
          data.mealComponentSteps.forEach(s => {
            steps.components[s.component_id] = s;
          });
          data.ingredientSteps.forEach(s => {
            steps.ingredients[s.meal_food_id] = s;
          });
          this.$set(this.steps, mealId, steps);
          return data;
        })
        .catch(e => {
          console.error('failed to fetch steps', e);
        })
    },
    meal_sizes() {
      return 'small,medium,large'.split(',');
    },
    getTotals(ingredients) {
      const totals = {
        cookedAmountValue: 0,
        rawAmountValue: 0
      }
      ingredients.forEach(i => {
        totals.cookedAmountValue += i.cookedAmountValue;
        totals.rawAmountValue += i.rawAmountValue;
      })
      totals.cookedAmount = formatWeight(totals.cookedAmountValue, this.units, this.decimalPlaces);
      totals.rawAmount = formatWeight(totals.rawAmountValue, this.units, this.decimalPlaces);
      return totals;
    },
    async closeDatePicker() {
      console.log('close date picker', this.datePickerDates);
      this.showDatePicker = false;
      this.dates = this.datePickerDates;
    },
    // setFilteredItems(filteredIngredients) {
    //   console.log('filteredIngredients', filteredIngredients);
    //   this.filteredIngredients = filteredIngredients;
    // }

    saveCategories(item) {
      console.log('saving categories ', JSON.stringify(item));
      const categories = item.category.split(',').map(s => s.trim());
      return this.updateIngredientPartial({ingredient: item, update: {categories}});
    },
    saveNote(item) {
      console.log('saving note ', item);
      return this.updateIngredientPartial({ingredient: item, update: {note: item.note}});
    },
    saveTrim(item) {
      const trim_multiplier = item.percentTrim
        ? 1 + (parseFloat(item.percentTrim) / 100)
        : null;
      return this.updateIngredientPartial({ingredient: item, update: {trim_multiplier}})
        .then(() => {
          console.log('item', item);
          const mealIds = Object.keys(this.ingredientToMealMap[item.foodid]).map(id => getMealId(id));
          mealIds.forEach(id => this.fetchMealIngredients({id, force: true}))
        });
    },
    savePrice(item) {
      if (!item.price && parseFloat(item.price) !== 0) {
        if (item.price !== item.oldPrice) {
          console.log('price empty, clearing');
          item.totalPrice = null;
          return this.updateIngredientPartial({ingredient: item, update: {price: null}})
            .then(r => {
              console.log('updated', r);
              item.oldPrice = null;
            });
        }
      } else {
        const price = (item.price * 100).toFixed(0);
        if (price === item.oldPrice) {
          console.log('price has not changed');
        } else {
          item.totalPrice = ((item.rawAmountValue / 1000) * item.price).toFixed(2)
          console.log('saving price ', {item, price});
          return this.updateIngredientPartial({ingredient: item, update: {price}})
            .then(r => {
              console.log('updated', r);
              item.oldPrice = price;
            });
        }
      }
    },
    saveTotalPrice(item) {
      const totalPrice = parseFloat(item.totalPrice);
      if (isNaN(totalPrice)) {
        item.price = null;
        return this.savePrice(item);
      } else {
        const amountInKg = (item.rawAmountValue / 1000)
        const price = (totalPrice / amountInKg).toFixed(2);
        console.log('saving via total price ', {item, price});
        item.price = price;
        return this.savePrice(item);
      }
    },
    saveName(item) {
      console.log('saving name ', item);
      return this.updateIngredientPartial({ingredient: item, update: {fooddescriptionsimple: item.editName}});
    },
    cancelReplaceIngredients() {
      this.showReplaceIngredients = false;
      this.replacement.ingredient = false;
      this.replaceSelected = [];
    },
    closeReplaceIngredients() {
      this.$nextTick(() => {
        const promises = [];
        if (this.showReplaceIngredients) {
          this.showReplaceIngredients = false;

          if (!this.replacement.ingredient) {
            alert('no replacement ingredient selected');
            return;
          }

          if (confirm('Are you sure?')) {
            const newFoodId = this.replacement.ingredient.foodid;
            for (const item of this.replaceSelected) {
              const oldFoodId = item.foodid;
              console.log('replacing selected ', JSON.stringify(item));
              for (const key of this.getMealsWithFood(oldFoodId)) {
                const [mealId,/* componentId */] = key.split('.');
                // need to do this to look up if it belongs to a component
                console.log('selected found in ', JSON.stringify(item));
                const mealIngredients = this.getMealIngredients(mealId)
                  .filter(i => i.foodid === item.foodid);
                console.log(`found ${mealIngredients.length} instances of the ingredient in this meal`, mealIngredients);
                promises.push(mealIngredients
                  .map(i => this.replaceIngredient(
                      {mealId, oldFoodId, newFoodId, componentId: i.component_id}
                    )
                  )
                );
              }
            }
          }
        }
        // need to clear this since after replacement old ingredients will still be selected (but no longer visible in the table!)
        this.replaceSelected = [];
        return Promise.all(promises);
      });
    },
    showReplaceItem(item) {
      this.replaceSelected = [item];
      this.showReplaceIngredients = true;
    },
    getMealsUsingIngredient(ingredient) {
      // console.log('this.orders',this.orders);
      return this
        .getMealsWithFood(ingredient.foodid)
        .map(id => ({
          ...this.getMeal(getMealId(id)),
          mealCount: this.orders.filter(o => o.meal_id == id).reduce((a, c) => c.quantity + a, 0)
        }))
        .filter(m => {
          if (!m) {
            console.warn('meal not found for ingredient', {
              ingredient,
              mealIds: this.ingredientToMealMap[ingredient.foodid].map(getMealId)
            });
          }
          return !!m
        });
    },
    formatDate(date) {
      // console.log('foramt date',[ date, moment(date).utc().format(moment.HTML5_FMT.DATE)]);
      return moment(date).utc().format(moment.HTML5_FMT.DATE);
    },
    dateFormatted,
    getMealFoods(meal_id, foodid) {
      const mealIngredients = this.getMealIngredients(meal_id);
      return mealIngredients && mealIngredients.filter(i => i.foodid === foodid)
    },
    getMealsWithFood(foodId) {
      return [...new Set(Object.keys(this.ingredientToMealMap[foodId]).map(getMealId))];
    },
    getCookedAmount({mealid, foodid, component_id}) {
      return this.ingredientToMealCookedMap[foodid][getKey(mealid, component_id)];
    },
    getRawAmount({mealid, foodid, component_id}) {
      return this.ingredientToMealRawMap[foodid][getKey(mealid, component_id)];
    },

  },
  props: {
    hideHeaders: {type: Array, default: null, required: false},
    prepOnly: {type: Boolean, default: false, required: false},
    enlargePrep: {type: Boolean, default: false, required: false},
    showIngredientSteps: {type: Boolean, default: false, required: false},
    showComponentSteps: {type: Boolean, default: false, required: false},
    disableTeamFilter: {type: Boolean, default: null, required: false},
    doNotLoadTeamAssignments: {type: Boolean, default: false, required: false},
    paging: {type: Boolean, default: false, required: false},
    excludeAllergy: {type: Boolean, default: false, required: false},
    showExportButton: {type: Boolean, default: false, required: false},
    dataAsTableFunction: {type: Function, required: false},
    dateRangePicker: {type: Boolean, default: false, required: false}
  }
}
</script>

<style>
.table-input {
  height: 30px;
  /*width: auto;*/
  font-size: 14px;
}

table td .top {
  vertical-align: top;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
  -moz-appearance: textfield; /* Firefox */
}
</style>
